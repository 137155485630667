<template>
  <div class="min-h-[500px] py-24">
    <div class="max-w-[1200px] mx-auto flex flex-wrap justify-center">
      <ul
        class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-6 lg:md:grid-cols-6 gap-x-4 gap-y-8 px-[10px] lg:px-0"
      >
        <div class="col-span-2 flex flex-col justify-center space-y-5">
          <div class="col-span-2 flex flex-col justify-center">
            <p class="text-3xl">Popular Movies</p>
            <p class="text-3xl">to Download Now</p>
          </div>
          <p class="text-ash-100">Most watched movies every day</p>
        </div>
        <li v-for="movie in series" :key="movie.id" class="group">
          <RouterLink :to="{ name: 'movieDetails', params: { id: movie.id } }">
            <div class="space-y-1">
              <div class="w-[100%]">
                <img
                  :src="movie.thumbnail"
                  class="object-cover w-full h-[200px] rounded"
                  alt=""
                />
              </div>
              <div class="flex">
                <p
                  v-for="category in movie.category"
                  :key="category"
                  class="text-[10px] text-ash-100"
                >
                  {{ category }},
                </p>
                <p class="text-[10px] text-ash-100">
                  {{ movie.yearReleased }}
                </p>
              </div>
              <p v-if="movie.subTitle" class="group-hover:text-ash-100">
                {{ movie.title }}:{{ movie.subTitle }}
              </p>
              <p v-if="!movie.subTitle" class="group-hover:text-ash-100">
                {{ movie.title }}
              </p>
            </div>
          </RouterLink>
        </li>
      </ul>
      <RouterLink :to="{ name: 'search', query: { type: 'popular' } }">
        <div class="flex items-center space-x-2 group mt-6 p-2">
          <p class="group-hover:text-ash-100">VIEW ALL</p>
          <rightIcon class="group-hover:text-ash-100" />
        </div>
      </RouterLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useMovieStore } from "@/store/movie";
import rightIcon from "@/shared/icons/rightIcon.vue";
import { ref, watchEffect } from "vue";
import { MovieDetail } from "@/types/movie";

const movieStore = useMovieStore();

let series = ref<MovieDetail[]>([]);

watchEffect(async () => {
  series.value = (await movieStore.getTypeApi(0, 9, "popular")) || [];
});
</script>

<style scoped></style>
