<template>
  <div
    class="h-[500px] background relative flex my-8"
    :style="{
      backgroundImage: singleMovie ? `url(${singleMovie.thumbnail})` : 'none',
    }"
  >
    <div
      class="absolute h-full w-full z-10 inset-0 bg-black opacity-40 overlay"
    ></div>
    <div class="max-w-[1200px] mx-auto h-full z-20 flex px-[10px] ld:px-0">
      <div class="flex flex-col justify-center w-1/2 space-y-4">
        <p class="mb-10">Top Blockbusters</p>
        <h1 class="text-5xl md:tracking-wider">{{ singleMovie?.title }}</h1>
        <p class="text-[14px] md:tracking-wider">
          {{ singleMovie?.description }}
        </p>
        <buttonApp
          :text="`View Now`"
          class="w-[150px]"
          @click="goToDetails(singleMovie?.id || '')"
        />
      </div>
    </div>
  </div>
  <div></div>
</template>

<script setup lang="ts">
import buttonApp from "@/shared/buttonApp.vue";
import { useMovieStore } from "@/store/movie";
import { MovieDetail } from "@/types/movie";
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";

const movieStore = useMovieStore();
const singleMovie = ref<MovieDetail | null>(null);

const router = useRouter();

const goToDetails = (id: string) => {
  router.push({ name: "movieDetails", params: { id: id } });
};

const getRandomIMovie = (arr: MovieDetail[]) => {
  const randomIndex = Math.floor(Math.random() * arr.length);
  return arr[randomIndex];
};

onMounted(async () => {
  const movies = (await movieStore.getTypeApi(0, 1, "block-buster")) || [];
  singleMovie.value = getRandomIMovie(movies);
});
</script>

<style scoped>
.background {
  background-size: cover;
  background-position: center;
}
</style>
