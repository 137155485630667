<template>
  <div
    class="bg-blue-200 min-h-screen flex flex-col text-white relative z-2 justify-between"
  >
    <nav-bar class="sticky top-0 bg-blue-200 z-50" />

    <RouterView />
    <Footer />
    <div
      v-if="scrollDistance > 1000"
      class="fixed bottom-8 flex right-3 bg-card-100 w-[70px] h-[70px] rounded-full items-center justify-center cursor-pointer z-50"
      @click="scrollToTop"
    >
      <top />
    </div>
    <div
      v-if="movieStore.menuToggle"
      class="flex flex-col w-[200px] space-y-8 fixed top-[60px] h-full bg-appColor z-50"
    >
      <ul class="flex flex-col mt-10 w-full items-center gap-3">
        <li class="hover:text-ash-100">
          <RouterLink to="/">Home</RouterLink>
        </li>
        <li class="hover:text-ash-100">
          <RouterLink to="/search">Movies</RouterLink>
        </li>
        <li class="hover:text-ash-100">
          <RouterLink to="/contact">Contact</RouterLink>
        </li>
      </ul>
      <div class="flex space-x-4 mt-auto justify-center">
        <a
          href="http://https://www.facebook.com/profile.php?id=61554638804421&mibextid=9R9pXO"
          target="_blank"
          rel="noopener noreferrer"
          class="hover:text-ash-100"
          ><Facebook
        /></a>
        <a
          href="https://www.tiktok.com/@screenvila?_t=8io9aIzQ4Ri&_r=1"
          target="_blank"
          rel="noopener noreferrer"
          class="hover:text-ash-100"
          ><Instagram
        /></a>
        <a
          href="https://www.tiktok.com/@screenvila?_t=8io9aIzQ4Ri&_r=1"
          target="_blank"
          rel="noopener noreferrer"
          class="hover:text-ash-100"
          ><TikTok
        /></a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onBeforeUnmount, computed } from "vue";
import navBar from "./components/navBar.vue";
import Footer from "@/components/Footer.vue";
import top from "@/shared/icons/top.vue";
import { useMovieStore } from "./store/movie";

import Facebook from "@/shared/icons/facebook.vue";
import Instagram from "@/shared/icons/instagram.vue";
import TikTok from "@/shared/icons/tiktok.vue";
const scrollY = ref(0);
const movieStore = useMovieStore();

// Remove the scroll event listener on component destroy
onBeforeUnmount(() => {
  window.removeEventListener("scroll", handleScroll);
});

const handleScroll = () => {
  // Update the scroll distance when the page is scrolled
  scrollY.value = window.scrollY;
};
// Use computed to get the scroll distance
const scrollDistance = computed(() => {
  return scrollY.value;
});

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};
</script>
<style></style>
