import { createClient } from "@supabase/supabase-js";

// Ensure that process.env.SUPABASE_URL and process.env.SUPABASE_KEY are defined
if (!process.env.VUE_APP_SUPABASE_URL || !process.env.VUE_APP_SUPABASE_KEY) {
  throw new Error(
    "SUPABASE_URL and SUPABASE_KEY must be defined in your environment."
  );
}

// Create the Supabase client
const supabaseUrl = process.env.VUE_APP_SUPABASE_URL;
const supabaseKey = process.env.VUE_APP_SUPABASE_KEY;
export const supabase = createClient(supabaseUrl, supabaseKey);
