<template>
  <div class="flex flex-col space-y-5 max-w-[1200px] mx-auto py-16">
    <div class="flex w-full justify-between items-center px-[10px] lg:px-0">
      <p>2019 Top movies</p>
      <hr class="flex-grow border-t border-gray-300 mx-2" />
      <p class="text-[12px] text-ash-100">Blockbusters</p>
    </div>
    <swiper
      :modules="[Navigation, Scrollbar, Autoplay]"
      :slides-per-view="5"
      :space-between="10"
      :loop="true"
      navigation
      :autoplay="{
        delay: 5000,
        pauseOnMouseEnter: true,
      }"
      :scrollbar="{ draggable: true }"
      class="h-[300px] w-full"
    >
      <swiper-slide
        v-for="movie in topMovies"
        :key="movie.id"
        class="bg-slate-600 wiper-slide min-w-[200px] flex rel"
        :style="{
          backgroundImage: `url(${movie.thumbnail})`,
        }"
      >
        <RouterLink :to="{ name: 'movieDetails', params: { id: movie.id } }">
          <div class="absolute inset-0 bg-black overlay z-10"></div>
          <div class="absolute z-20 flex flex-col justify-end h-full p-3">
            <div class="flex space-x-1">
              <p
                v-for="genre in movie.category"
                :key="genre"
                class="text-[10px] text-ash-100"
              >
                {{ genre }},
              </p>
              <p class="text-[10px] text-ash-100">
                {{ movie.yearReleased }}
              </p>
            </div>
            <p>{{ movie.title }}</p>
          </div>
        </RouterLink>
      </swiper-slide>
    </swiper>
    <RouterLink :to="{ name: 'search', query: { type: 'top' } }">
      <div class="flex justify-end space-x-[5px] group mt-6 text-[12px]">
        <p class="group-hover:text-ash-100">VIEW ALL</p>
        <rightIcon class="group-hover:text-ash-100 h-[15px]" />
      </div>
    </RouterLink>
  </div>
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Scrollbar, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";

import rightIcon from "@/shared/icons/rightIcon.vue";

import { useMovieStore } from "@/store/movie";
import { ref, watchEffect } from "vue";
import { MovieDetail } from "@/types/movie";
let topMovies = ref<MovieDetail[]>([]);

const movieStore = useMovieStore();

watchEffect(async () => {
  topMovies.value = (await movieStore.getTypeApi(0, 19, "top")) || [];
});
</script>

<style scoped>
.wiper-slide {
  background: no-repeat;
  background-size: cover;
  background-position: center;
}
.overlay {
  background: linear-gradient(
    180deg,
    rgba(17, 206, 204, 0) 19%,
    rgba(0, 0, 0, 1) 90%
  );
}
</style>
