<template>
  <div
    class="bg-white h-[60vh] flex heroe relative"
    :style="{
      backgroundImage: getHeroeActive
        ? `url(${getHeroeActive.thumbnail})`
        : 'none',
      backgroundSize: 'cover',
    }"
  >
    <div class="absolute inset-0 bg-black opacity-50 z-10"></div>
    <div
      class="z-20 mx-auto h-full w-full max-w-[1200px] flex pb-4 px-[10px] lg:px-0"
    >
      <div class="flex flex-col justify-end w-full md:w-1/2">
        <div class="flex space-x-2 text-ash-200">
          <ul
            class="flex space-x-2 mb-2"
            v-if="getHeroeActive && getHeroeActive.category"
          >
            <li v-for="genre in getHeroeActive.category" :key="genre">
              {{ genre }}
            </li>
          </ul>
          <span>|</span>
          <span>{{ getHeroeActive && getHeroeActive.yearReleased }}</span>
          <span>|</span>
          <span>{{ getHeroeActive && getHeroeActive.length }}</span>
        </div>
        <h1 class="text-5xl md:tracking-wider">
          {{ getHeroeActive && getHeroeActive.title }}
          <span v-if="getHeroeActive && getHeroeActive.subTitle" class="-ml-4"
            >:</span
          >
        </h1>
        <h1 class="text-5xl max-w-[350px] md:tracking-wider">
          {{ getHeroeActive && getHeroeActive?.subTitle }}
        </h1>
        <buttonApp
          :text="`View Now`"
          class="w-[150px]"
          @click="goToDetails(getHeroeActive?.id || '')"
        />
      </div>
      <div class="hidden md:flex items-end w-1/2 justify-end">
        <ul class="flex space-x-1">
          <li v-for="movie in getFiveMovies" :key="movie.title">
            <div
              class="hover:shadow w-[80px] sm:max-w-[100px] hover:shadow-blue-600 cursor-pointer"
              @click="changeActiveHeroe(movie)"
            >
              <img
                :src="movie.thumbnail"
                :alt="`${movie?.thumbnail}`"
                class="object-cover h-[80px] md:h-[130px] rounded"
              />
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useMovieStore } from "@/store/movie";
import { MovieDetail } from "@/types/movie";
import { onBeforeMount, ref } from "vue";
import buttonApp from "@/shared/buttonApp.vue";
import { useRouter } from "vue-router";

const router = useRouter();

const getFiveMovies = ref<MovieDetail[]>([]);
const movieStore = useMovieStore();
const getHeroeActive = ref<MovieDetail | null>(null);

const goToDetails = (id: string) => {
  router.push({ name: "movieDetails", params: { id: id } });
};

const fetchData = async () => {
  try {
    return (await movieStore.getFiveMovies()) || [];
  } catch (error) {
    console.error("An error occurred while fetching data:", error);
    return [];
  }
};
onBeforeMount(async () => {
  getFiveMovies.value = await fetchData();
  console.log("fiveMoves", getFiveMovies.value);

  if (!getHeroeActive.value) {
    const data = await fetchData();
    getHeroeActive.value = data[0];
  } else {
    getHeroeActive.value = movieStore.getHeroeActive;
  }
});

const changeActiveHeroe = (movie: MovieDetail) => {
  getHeroeActive.value = movie;
};
</script>

<style scoped>
.heroe {
  background: no-repeat;
  background-size: cover;
  background-position: center;
}
</style>
