<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 15 15"
    :class="`fill-white hover:fill-ash-100`"
  >
    <path stroke="currentColor" d="m7.5 1.5l4 4m-4-4l-4 4m4-4V12M1 13.5h13" />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped></style>
