<template>
  <div>
    <div
      class="border-b h-[60px] border-ash-20 flex items-center px-3 bg-appColor"
    >
      <div class="flex space-x-2 justify-between w-full items-center">
        <div class="flex space-x-6 items-center justify-between w-full">
          <div class="flex justify-center items-center">
            <p
              class="mr-2 h-4 cursor-pointer flex sm:hidden"
              @click="movieStore.toggleMenu"
            >
              &#9778;
            </p>
            <RouterLink to="/">
              <img src="../assets/images/logo.png" class="h-12" alt="logo" />
            </RouterLink>
          </div>
          <ul class="flex space-x-5 mt-2 w-full justify-end">
            <li class="hover:text-ash-100 hidden sm:flex">
              <RouterLink to="/">Home</RouterLink>
            </li>
            <li class="hover:text-ash-100 hidden sm:flex">
              <RouterLink to="/search">Movies</RouterLink>
            </li>
            <li class="hover:text-ash-100 hidden sm:flex">
              <RouterLink to="/contact">Contact</RouterLink>
            </li>
            <li
              class="hover:text-ash-100 cursor-pointer"
              @click="toggleSearchInput"
            >
              <search />
            </li>
          </ul>
          <div
            v-if="$route.path === '/search' && scrollX < 976"
            class="bg-card-100 p-1 px-2 cursor-pointer flex items-center h-8"
            @click="toggleFilter"
          >
            <p>&#9778;</p>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="$route.path === '/search' && searchInputVisibilty"
      class="flex px-4 items-center py-[5px] rounded-lg border-2 border-ash-100 mx-2 mt-1"
    >
      <input
        v-model="searchInput"
        type="text"
        placeholder="search for titles"
        class="bg-transparent border-ash-300 h-[30px] w-full focus:outline-none placeholder:text-ash-100"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useMovieStore } from "@/store/movie";
import { onMounted, ref, watch, watchEffect } from "vue";
import { useRoute, useRouter } from "vue-router";
import search from "@/shared/icons/search.vue";

const router = useRouter();
const route = useRoute();
const scrollX = ref(window.innerWidth);
const movieStore = useMovieStore();

const searchInput = ref();
const searchInputVisibilty = ref(false);

const toggleFilter = () => {
  movieStore.toggleFilter();
};

const toggleSearchInput = () => {
  router.push({ name: "search" });
  searchInputVisibilty.value = !searchInputVisibilty.value;
};

const updateQuery = () => {
  const currentQuery = {
    ...router.currentRoute.value.query,
    search: searchInput.value,
  };
  if (searchInput.value !== "") {
    router.replace({ query: currentQuery });
  } else {
    const { search, ...rest } = currentQuery;
    console.log(search);
    router.replace({
      ...router.currentRoute.value,
      query: rest,
    });
  }
};
const updateInputFromUrl = () => {
  if (route.query.search) searchInput.value = route.query.search as string;
};
watchEffect(() => {
  updateInputFromUrl();

  if (searchInputVisibilty.value === false) {
    searchInput.value = "";
  }
});
watchEffect(() => {
  updateQuery;
});

watch(scrollX, () => {
  if (scrollX.value < 976) {
    movieStore.toggleFilterFalse();
  }
});

watch(searchInput, updateQuery);

onMounted(async () => {
  // sendQuery();
  window.addEventListener("resize", handleResize);
});

const handleResize = () => {
  // Update the scroll distance when the page is resized
  scrollX.value = window.innerWidth;
};

// Use computed to get the scroll distance
// const scrollXDistance = computed(() => {
//   return scrollX.value;
// });
</script>

<style scoped></style>
