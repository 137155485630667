<template>
  <button :class="`btn bg-appColor hover:bg-blue-700   mt-5`">
    {{ props.text }}
  </button>
</template>

<script setup lang="ts">
import { defineProps } from "vue";
const props = defineProps({
  width: {
    type: [String, Number],
    default: "150px",
  },
  text: {
    type: [String, Number],
    default: "Download Now",
  },
  bgColor: {
    type: String,
    default: "100",
  },
});
</script>

<style scoped></style>
