<template>
  <div class="min-h-[500px] py-12">
    <div class="max-w-[1200px] mx-auto flex flex-wrap justify-center space-y-8">
      <div class="flex w-full justify-between items-center px-[10px] lg:px-0">
        <p>Romantic</p>
        <hr class="flex-grow border-t border-gray-300 mx-2" />
        <p class="text-[12px] text-ash-100">Love portions</p>
      </div>
      <ul
        class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:md:grid-cols-5 gap-x-4 gap-y-8 px-[10px] lg:px-0"
      >
        <li
          v-for="(movie, index) in romance"
          :key="movie.id"
          :class="` group  ${index === 0 ? 'col-span-2  row-span-2' : ''}`"
        >
          <RouterLink :to="{ name: 'movieDetails', params: { id: movie.id } }">
            <div class="space-y-1">
              <div class="w-[100%]">
                <img
                  :src="movie.thumbnail"
                  :class="`object-cover h-[120px] w-[100%] ${
                    index == 0 ? 'h-[343px]' : `h-[120px]`
                  }`"
                  alt=""
                />
              </div>
              <div class="flex space-x-1">
                <p
                  v-for="category in movie.category"
                  :key="category"
                  class="text-[10px] text-ash-100"
                >
                  {{ category }},
                </p>
                <p class="text-[10px] text-ash-100">
                  {{ movie.yearReleased }}
                </p>
              </div>
              <p v-if="movie.subTitle" class="group-hover:text-ash-100">
                {{ movie.title }}:{{ movie.subTitle }}
              </p>
              <p v-if="!movie.subTitle" class="group-hover:text-ash-100">
                {{ movie.title }}
              </p>
            </div>
          </RouterLink>
        </li>
      </ul>
      <RouterLink :to="{ name: 'search', query: { category: 'romance' } }">
        <div class="flex items-center space-x-2 group mt-6">
          <p class="group-hover:text-ash-100">VIEW ALL</p>
          <rightIcon class="group-hover:text-ash-100" />
        </div>
      </RouterLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useMovieStore } from "@/store/movie";
import rightIcon from "@/shared/icons/rightIcon.vue";
import { onMounted, onUnmounted, ref, watchEffect } from "vue";
import { MovieDetail } from "@/types/movie";

const movieStore = useMovieStore();
const screenWidth = ref(window.innerWidth);
let romance = ref<MovieDetail[]>([]);

const fetchData = async () => {
  try {
    if (screenWidth.value > 449 && screenWidth.value <= 976) {
      return (await movieStore.getCategoryApi(0, 6, "romance")) || [];
    } else {
      return (await movieStore.getCategoryApi(0, 7, "romance")) || [];
    }
  } catch (error) {
    console.error("An error occurred while fetching data:", error);
    return [];
  }
};

watchEffect(async () => {
  romance.value = await fetchData();
});

onMounted(() => {
  window.addEventListener("resize", handleResize);
});

onUnmounted(() => {
  window.removeEventListener("resize", handleResize);
});

// Function to handle window resize
const handleResize = () => {
  return (screenWidth.value = window.innerWidth);
};
</script>

<style scoped></style>
