import { supabase } from "@/lib/supabaseClient";
import { Category, CommentForm, MovieDetail, MovieState } from "@/types/movie";
import { defineStore } from "pinia";
import emailjs from "emailjs-com";

export const useMovieStore = defineStore("movieStore", {
  state: (): MovieState => ({
    movies: [],
    heroeActive: {},
    selectedCategory: [],
    selectedYear: null,
    hasNextPage: true,
    filterToggle: false,
    menuToggle: false,
    messageSuccess: false,
    messageFailure: false,
  }),

  getters: {
    getMovies(state: MovieState): MovieDetail[] {
      console.log("movied", this.movies);
      return state.movies;
    },
    getHeroeActive(state: MovieState): MovieDetail {
      return state.heroeActive as MovieDetail;
    },
    getNextPage(state: MovieState): boolean {
      return state.hasNextPage;
    },

    getUniqueYear(state: MovieState) {
      const uniqueYear = new Set<number>();

      state.movies.forEach((movie) => {
        const year = movie.yearReleased;

        // Check if year is defined before adding to the set
        if (year !== undefined) {
          uniqueYear.add(year);
        }
      });

      const sortedYears = Array.from(uniqueYear).sort((a, b) => a - b);

      return sortedYears;
    },
    getUniqueCategory(state: MovieState) {
      const uniqueCategory = new Set<Category>();

      state.movies.forEach((movie) => {
        const movieDetail = movie;

        // Check if category is defined before accessing it
        if (movieDetail.category !== undefined) {
          movieDetail.category.forEach((cat) => {
            uniqueCategory.add(cat);
          });
        }
      });

      return Array.from(uniqueCategory);
    },
  },
  actions: {
    toggleFilterFalse() {
      this.filterToggle = false;
    },
    toggleMenu() {
      this.menuToggle = !this.menuToggle;
    },
    toggleFilter() {
      this.filterToggle = !this.filterToggle;
    },
    async getMoviesApi(
      column: string | "*",
      category: string,
      page = 1,
      search?: string,
      type?: string
    ) {
      let movies: MovieDetail[] = [];
      try {
        const getAndCheckMovies = async () => {
          const ITEMS_PER_PAGE = 25;
          const startIdx = (page - 1) * ITEMS_PER_PAGE;
          const endIdx = startIdx + ITEMS_PER_PAGE - 1;
          if (column && !search && !type) {
            const categoriesArray = (category?.split(",") || []) as string[];
            column = "category";
            console.log("categoriesArray", categoriesArray);

            const { data, error } = await supabase
              .from("Movies")
              .select("*")
              .contains(column, [category])
              .range(startIdx, endIdx)
              .order("created_at", { ascending: false });
            if (error) console.log(error);
            movies = data as [];
            return movies;
          } else if (column && search && !type) {
            const categoriesArray = (category?.split(",") || []) as string[];
            column = "category";

            const { data, error } = await supabase
              .from("Movies")
              .select("*")
              .contains(column, categoriesArray)
              .ilike("title", `%${search}%`)
              .range(startIdx, endIdx)
              .order("created_at", { ascending: false });
            if (error) console.log(error);

            movies = data as [];
            return movies;
          } else if (column && !search && type) {
            const categoriesArray = (category?.split(",") || []) as string[];
            column = "category";

            const { data, error } = await supabase
              .from("Movies")
              .select("*")
              .contains(column, categoriesArray)
              .contains("types", [type])
              .range(startIdx, endIdx)
              .order("created_at", { ascending: false });
            if (error) console.log(error);
            movies = data as [];
            return movies;
          } else if (!column && search && type) {
            const { data, error } = await supabase
              .from("Movies")
              .select("*")
              .contains("types", [type])
              .ilike("title", `%${search}%`)
              .range(startIdx, endIdx)
              .order("created_at", { ascending: false });
            if (error) console.log(error);
            movies = data as [];
            return movies;
          } else if (!column && search && !type) {
            const { data, error } = await supabase
              .from("Movies")
              .select("*")
              .ilike("title", `%${search}%`)
              .range(startIdx, endIdx)
              .order("created_at", { ascending: false });
            if (error) console.log(error);
            movies = data as [];
            return movies;
          } else if (!column && !search && type) {
            const { data, error } = await supabase
              .from("Movies")
              .select("*")
              .contains("types", [type])
              .range(startIdx, endIdx)
              .order("created_at", { ascending: false });
            if (error) console.log(error);
            // if (repeat === false)
            movies = data as [];
            return movies;
          } else if (column && search && type) {
            const categoriesArray = (category?.split(",") || []) as string[];
            column = "category";

            const { data, error } = await supabase
              .from("Movies")
              .select("*")
              .contains(column, categoriesArray)
              .contains("types", [type])
              .ilike("title", `%${search}%`)
              .range(startIdx, endIdx)
              .order("created_at", { ascending: false });
            if (error) console.log(error);

            movies = data as [];
            return movies;
          } else {
            const { data, error } = await supabase
              .from("Movies")
              .select("*")
              .range(startIdx, endIdx)
              .order("created_at", { ascending: false });
            if (error) console.log(error);

            movies = data as [];
            return movies;
          }
        };
        await getAndCheckMovies();
        this.movies = movies;
        page = page + 1;
        const nextMovie = (await getAndCheckMovies()) || [];
        if (nextMovie.length > 0) this.hasNextPage = true;
        if (nextMovie.length === 0) this.hasNextPage = false;
        console.log("hasNextPage", this.hasNextPage);
      } catch (error) {
        console.error("An unexpected error occurred:", error);
        return [];
      }
    },
    async getFiveMovies(page = 1) {
      const ITEMS_PER_PAGE = 5;
      const startIdx = (page - 1) * ITEMS_PER_PAGE;
      const endIdx = startIdx + ITEMS_PER_PAGE - 1;
      const { data, error } = await supabase
        .from("Movies")
        .select("*")
        .range(startIdx, endIdx)
        .order("created_at", { ascending: false });
      if (error) console.log(error);

      return data;
    },
    async getMovie(movieId: string): Promise<MovieDetail | null> {
      const { data, error } = await supabase
        .from("Movies")
        .select("*")
        .eq("id", movieId);

      if (error) {
        console.error(error);
        return null;
      }

      return data ? data[0] : null;
    },
    async getTypeApi(
      startIdx: number,
      endIdx: number,
      type: string
    ): Promise<MovieDetail[] | null> {
      const { data, error } = await supabase
        .from("Movies")
        .select("*")
        .contains("types", [type])
        .range(startIdx, endIdx)
        .order("created_at", { ascending: false });
      if (error) console.log(error);

      return data;

      // // Create a map to store movies with unique titles
      // const uniqueTitlesMap = new Map<string, MovieDetail>();

      // // Iterate over the movies and add them to the map based on unique titles
      // data?.forEach((movie) => {
      //   const title = movie.title.toLowerCase(); // Convert to lowercase for case-insensitive comparison

      //   // Check if title is not in the map, add it
      //   if (!uniqueTitlesMap.has(title)) {
      //     uniqueTitlesMap.set(title, movie);
      //   }
      // });

      // // Convert the map values back to an array
      // const filteredData = Array.from(uniqueTitlesMap.values());

      // return filteredData || null;
    },
    async getSeasonalApi(
      startIdx: number,
      endIdx: number,
      type: string
    ): Promise<MovieDetail[] | null> {
      const response = await supabase
        .from("Movies")
        .select("*")
        .contains("types", [type])
        .range(startIdx, endIdx)
        .order("created_at", { ascending: false });

      if (response.error) {
        console.error(response.error);
        return null;
      }

      // Extract the array of items from the response
      const data = response.data;

      if (!data) {
        return null;
      }

      // Create a map to store movies with unique titles
      const uniqueTitlesMap = new Map<string, MovieDetail>();

      // Iterate over the movies and add them to the map based on unique titles
      const filteredData = data.filter((movie) => {
        const title = movie.title.toLowerCase(); // Convert to lowercase for case-insensitive comparison

        // Check if title is not in the map, add it
        if (!uniqueTitlesMap.has(title)) {
          uniqueTitlesMap.set(title, movie);
          return true; // Include the movie in the result
        }

        return false; // Exclude the movie from the result
      });

      // Calculate the remaining range to match the given startIdx and endIdx
      const remainingRange = endIdx - filteredData.length;

      // Fetch additional movies to meet the remaining range
      if (remainingRange > 0) {
        const additionalResponse = await supabase
          .from("Movies")
          .select("*")
          .contains("types", [type])
          .range(endIdx, endIdx + remainingRange)
          .order("created_at", { ascending: false });

        const additionalData = additionalResponse.data;

        additionalData?.forEach((movie) => {
          const title = movie.title.toLowerCase();

          // Check if title is not in the map, add it
          if (!uniqueTitlesMap.has(title)) {
            uniqueTitlesMap.set(title, movie);
            filteredData.push(movie);
          }
        });
      }

      return filteredData || null;
    },
    async getCategoryApi(
      startIdx: number,
      endIdx: number,
      category: string
    ): Promise<MovieDetail[] | null> {
      const { data, error } = await supabase
        .from("Movies")
        .select("*")
        .contains("category", [category])
        .range(startIdx, endIdx)
        .order("created_at", { ascending: false });
      if (error) console.log(error);

      return data;
    },
    async getRelatedMovies(category: string, movieId: string, page = 1) {
      let movies: MovieDetail[] = [];
      const ITEMS_PER_PAGE = 15;
      const startIdx = (page - 1) * ITEMS_PER_PAGE;
      const endIdx = startIdx + ITEMS_PER_PAGE - 1;

      const categoriesArray =
        typeof category === "string"
          ? category.split(",").map((cat) => cat.trim())
          : [];

      const { data, error } = await supabase
        .from("Movies")
        .select("*")
        .contains("category", [categoriesArray])
        .neq("id", movieId)
        .range(startIdx, endIdx)
        .order("created_at", { ascending: false });

      if (error) console.log(error);
      movies = data as [];
      return movies;
    },

    async getRemainingSeason(title: string) {
      let movies: MovieDetail[] = [];
      const { data, error } = await supabase
        .from("Movies")
        .select("*")
        .ilike("title", title)
        .order("created_at", { ascending: false });
      if (error) console.log(error);
      movies = data as [];
      return movies;
    },
    async createComment(comment: CommentForm) {
      const { error } = await supabase
        .from("Comments")
        .insert([comment])
        .select();
      if (error) console.log(error);
    },
    async sendMessage(value: string) {
      await emailjs
        .sendForm(
          "service_olum181",
          "template_y9qp0m9",
          value,
          "Djx-ps2RhoX-7dgwf"
        )
        .then(
          () => {
            this.messageSuccess = !this.messageSuccess;
            setTimeout(() => {
              this.messageSuccess = !this.messageSuccess;
            }, 3000);
          },
          () => {
            this.messageFailure = !this.messageFailure;
            setTimeout(() => {
              this.messageFailure = !this.messageFailure;
            }, 3000);
          }
        );
    },
    async getComments(movieId: string) {
      const { data, error } = await supabase
        .from("Comments")
        .select("*")
        .eq("movieId", movieId);
      if (error) console.log(error);
      return data;
    },
    async createRating(rating: number, movieId: string, ip_address: string) {
      // Check if a rating with the given IP address already exists
      const { data } = await supabase
        .from("Ratings")
        .select("movieId, ip_address")
        .eq("ip_address", ip_address)
        .eq("movieId", movieId)
        .limit(1);
      if (data?.length === 0) {
        // No existing rating for the given IP address, proceed with insertion
        const { error } = await supabase
          .from("Ratings")
          .insert([{ rating, movieId, ip_address }])
          .select();
        if (error) console.log(error);
      } else {
        // Rating already exists for the given IP address, update the existing record
        const { error } = await supabase
          .from("Ratings")
          .update({ rating })
          .eq("ip_address", ip_address)
          .eq("movieId", movieId)
          .select();
        if (error) console.log(error);
      }
    },
    async getAllRatings(movieId: string) {
      const { data, error } = await supabase
        .from("Ratings")
        .select("*")
        .eq("movieId", movieId);
      if (error) console.log(error);
      return data;
    },
    async getMovieRating(movieId: string, ip_address: string) {
      const { data, error } = await supabase
        .from("Ratings")
        .select("movieId, ip_address, rating")
        .eq("ip_address", ip_address)
        .eq("movieId", movieId)
        .limit(1);
      if (error) console.log(error);
      return data;
    },
    async createLike(movieId: string, ip_address: string) {
      // Check if a rating with the given IP address already exists
      const { data } = await supabase
        .from("Likes")
        .select("movieId, ip_address")
        .eq("ip_address", ip_address)
        .eq("movieId", movieId)
        .limit(1);
      console.log("check", data, ip_address);
      if (data?.length !== 0) {
        // No existing rating for the given IP address, proceed with insertion
        const { error, data } = await supabase
          .from("Likes")
          .delete()
          .match({ movieId: movieId, ip_address: ip_address });
        console.log("delete", data, ip_address);
        if (error) console.log(error);
      } else {
        // Rating already exists for the given IP address, update the existing record
        const { data, error } = await supabase
          .from("Likes")
          .insert([{ movieId, ip_address }])
          .select();
        console.log("inser", data, ip_address);
        if (error) console.log(error);
      }
    },
    async getMovieLikes(movieId: string) {
      const { data, error } = await supabase
        .from("Likes")
        .select("movieId, ip_address")
        .eq("movieId", movieId)
        .limit(1);
      if (error) console.log(error);
      return data;
    },

    // async getRemainingEpisodes(title: string, season: number) {
    //   let movies: MovieDetail[] = [];
    //   // const ITEMS_PER_PAGE = 15;
    //   // const startIdx = (page - 1) * ITEMS_PER_PAGE;
    //   // const endIdx = startIdx + ITEMS_PER_PAGE - 1;
    //   const { data, error } = await supabase
    //     .from("Movies")
    //     .select("*")
    //     .ilike("title", title)
    //     .eq("season", season)
    //     .order("created_at", { ascending: false });
    //   if (error) console.log(error);
    //   movies = data as [];
    //   return movies;
    // },
  },
});
